import {Component, OnInit, ChangeDetectionStrategy, HostListener, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import { KoppelingAanmakenPageViewModelImpl } from './koppeling-aanmaken-page-view-model-impl';
import { AbstractButton, BUTTON_TYPE } from '../bottombar/bottombar.component';
import { KoppelingAanmakenStap2ComponentViewModel } from '../layout/koppeling-aanmaken-stap2/koppeling-aanmaken-stap2.component';
import {fetchGeselecteerdeKoppelpartij, setGeselecteerdeKoppelpartij} from '../state/privacydashboard/privacydashboard.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {KoppelingHeaderViewModel} from '../layout/koppeling-header/koppeling-header.component';
import {map, takeWhile} from 'rxjs/operators';
import {selectGeselecteerdeKoppelpartij} from '../state/privacydashboard/privacydashboard.selectors';
import {AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';
import {GoBackHeaderViewModel} from '../layout/go-back-header/go-back-header.component';
import { selectKoppelpartijParam } from '../state/router/router.selectors';

@Component({
    selector: 'app-koppeling-aanmaken-page',
    templateUrl: './koppeling-aanmaken-page.component.html',
    styleUrls: ['./koppeling-aanmaken-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class KoppelingAanmakenPageComponent implements OnInit, OnDestroy {
    public koppelpartij$: Observable<AVGExtendedKoppelpartij>;
    public viewModel: KoppelingAanmakenPageViewModelImpl;
    public isBewerkenPage: boolean;

    public koppelingHeaderViewModel: KoppelingHeaderViewModel;
    public stap2ViewModel: KoppelingAanmakenStap2ComponentViewModel;

    public annulerenKnop: AbstractButton;
    public volgendeKnop: AbstractButton;

    public terugNaarOverzichtPopup = false;
    public magWegNavigeren = true;

    private subscriptions = [];

    public goBackHeaderViewModel: GoBackHeaderViewModel;

    @HostListener('window:popstate', ['$event']) onPopState(event: PopStateEvent): void {
        if (!this.magWegNavigeren){
            // backbutton preventen kan niet, dus er zal altijd een -1 history uitgevoerd worden.
            // Daarom doen we zelf weer een +1
            history.go(1);
            this.terugNaarOverzicht();
        }
    }

    constructor(private router: Router, private store: Store<AppState>) {}

    ngOnInit(): void {
        this.store.select(selectKoppelpartijParam)
        .pipe(takeWhile(koppelpartijUUID => !koppelpartijUUID, true)).subscribe((koppelpartijUUID) => {
            if (koppelpartijUUID) {
                this.store.dispatch(fetchGeselecteerdeKoppelpartij({koppelpartijUUID}));
            }
        });
        this.isBewerkenPage = this.router.url.split('?')[0].toLowerCase().includes('koppelingbewerken');
        this.koppelpartij$ = this.store.select(selectGeselecteerdeKoppelpartij);
        this.viewModel = new KoppelingAanmakenPageViewModelImpl(this.router, this.store);
        this.subscriptions.push(this.viewModel.onGetMagWegnavigeren.subscribe(b => this.magWegNavigeren = b));
        this.loadHeaderViewModels();
        this.loadButtons();
        this.loadStap2ViewModel();
    }

    loadHeaderViewModels(): void {
      this.koppelingHeaderViewModel = {
        img: this.koppelpartij$.pipe(map(k => k?.koppelpartijLogoUrl ? k?.koppelpartijLogoUrl : 'assets/img/icons/Symbool/Koppelpartij_icon_placeholder.svg')),
        title: this.koppelpartij$.pipe(map(k => k?.koppelpartijNaam)),
        subTitle: this.koppelpartij$.pipe(map(k => k?.koppelpartijBedrijfsnaam)),
        button: null,
        buttonClass: null,
        buttonIcon: null,
        onButtonClick: null,
        menu: null,
        showMenu: new BehaviorSubject<boolean>(false),
      };

      const koppelpartijUUID = this.router.url.split('/')[3];
      this.goBackHeaderViewModel = {
        title: this.isBewerkenPage ? 'Koppeling bewerken' : 'Koppeling aanmaken',
        backUrl: 'privacydashboard/koppelpartij/details/' + koppelpartijUUID,
        backTitle: 'Terug naar product'
      };
    }

    loadButtons(): void {
      this.subscriptions.push(combineLatest([this.viewModel.onGetStap, this.viewModel.onGetVolgendeButtonActive]).subscribe(([stap, volgendeButtonActive]) => {
        this.volgendeKnop = {action: () => this.viewModel.doVolgendeButtonAction.next(), buttonType: stap === 3 ? BUTTON_TYPE.POSITIVE : BUTTON_TYPE.SECONDARY, enabled: volgendeButtonActive, text: stap === 3 ? (this.isBewerkenPage ? 'Koppeling bewerken' : 'Koppeling aanmaken') : 'Volgende'};
        this.annulerenKnop = {action: () => this.viewModel.doVorigeButtonAction.next(), buttonType: BUTTON_TYPE.OUTLINE, enabled: true, text: stap === 1 ? 'Annuleren' : 'Vorige'};
      }));
    }

    loadStap2ViewModel(): void {
      this.stap2ViewModel = {
        onGetKoppelpartijNaam: this.viewModel.onGetKoppelpartijNaam,
        onGetVelden: this.viewModel.onGetVelden,
        doResetVelden: this.viewModel.doResetVelden,
        onGetWritePermissies: this.viewModel.onGetWritePermissions,
      };
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.viewModel.onDestroy();
        this.store.dispatch(setGeselecteerdeKoppelpartij({koppelpartij: null}));
    }

    public terugNaarOverzicht(): void {
      this.magWegNavigeren ? this.router.navigateByUrl('privacydashboard') : this.openPopup();
    }

    public openPopup(): void {
      this.terugNaarOverzichtPopup = true;
    }

    public terugNaarOverzichtPopupSluiten(): void {
        this.terugNaarOverzichtPopup = false;
    }

    public jaIkWeetHetZekerTerugNaarOverzicht(): void {
        this.magWegNavigeren = true;
        this.router.navigateByUrl('privacydashboard/aanbod');
    }
}
