<div class="subheader">
    <p class="bodyContentSemi stap">
        Stap 2/3 - Datatoegang
    </p>
    <p class="labelLabelSemi uitleg" (click)="showUitleg()">
        Waar komen deze velden vandaan?
    </p>
    <div class="reset" (click)="viewModel.doResetVelden.next()" (mouseenter)="showResetTooltip()" (mouseleave)="hideResetTooltip()">
        <div class="tooltip resetTooltip" *ngIf="toonResetTooltip">
            <p class="bodySmallContent">Alle wijzigingen ongedaan maken</p>
        </div>
    </div>
</div>

<div class="veldentable">
    <ng-container *ngFor="let endpoint of viewModel.onGetVelden | async; let i = index">
        <div [class]="endpointClass(endpoint, i)">
            <p class="bodyContentBold name">{{endpoint.naam}}</p>
            <span *ngIf="endpoint.writePermissies" class="labelLabelSemi label warning">
              <i class="uitwisselen"></i>
              Inzien én bewerken
              <div class="tooltip">
                <p class="bodySmallContent">
                  {{viewModel.onGetKoppelpartijNaam | async}} kan ook enkele gegevens bewerken. Dit betreft alle velden met <i class="uitwisselen"></i> ernaast.
                </p>
              </div>
            </span>
            <div class="categorien">
                <ng-container *ngFor="let categorie of endpoint.categorieen;">
                    <div class="categorie">
                        <p class="bodySmallContentSemi naam">{{categorie.naam}}</p>
                        <div *ngIf="isVrijVeld(categorie, endpoint)" class="iconContainer"><i class="bodySmallContentSemi naam labelpadding">Lezen</i><i class="bodySmallContentSemi naam">Schrijven</i></div>
                        <div class="velden">
                            <ng-container *ngFor="let veld of categorie.velden;">
                                <div [class]="veldClass(veld)">
                                    <div class="iconContainer"><i [class]="veldIconClass(veld)" (click)="onVeldClicked(veld)"></i></div>
                                    <div *ngIf="isVrijVeld(categorie, endpoint)" class="iconContainer checkboxpadding"><i [class]="veldSchrijvenIconClass(veld)" (click)="onVrijVeldSchrijvenClicked(veld)"></i></div>
                                    <p class="bodyContent veldnaam" (click)="onVeldClicked(veld)">{{veld.naam}}</p>
                                    <div *ngIf="veld.properties?.length > 0" class="remarkContainer">
                                        <ng-container *ngFor="let prop of veld.properties;">
                                            <div [class]="propertyClass(prop)">
                                                <div class="tooltip remarkTooltip">
                                                    <p class="bodySmallContent">{{prop.description}}</p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<app-modal *ngIf="toonUitleg" (click)="closeUitleg()">
    <div class="modal__header">
        <div class="header__icon svg_icon-present"></div>
        <p class="header__title blue bodyContentBold">Basisset staat al klaar!</p>
        <div class="close" (click)="closeUitleg()"></div>
    </div>
    <div class="modal__body">
        <p class="body__text bodyContent">{{viewModel.onGetKoppelpartijNaam | async}} {{bevatOptioneleVelden ? 'heeft een aantal velden al voorgeselecteerd, dit zijn verplichte velden. Je kunt hier zelf nog velden aan toevoegen' : 'heeft aangegeven deze velden minimaal nodig te hebben. Je kunt voor dit product geen velden toevoegen of verwijderen'}}. Controleer goed of de data overeenkomt met jullie verwerkersovereenkomst.</p>
    </div>
    <div class="modal__buttons modal__buttons--no_border">
      <div class="button button--cta button--single" (click)="closeUitleg()">
            <p class="bodySmallContentSemi buttonCaption">Oké</p>
      </div>
    </div>
</app-modal>
